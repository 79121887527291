import React, { useState, useEffect } from 'react';
import teamsData from '../data/teams.json';

export interface Palmares {
    place: number;
    tournament: string;
  }
  
  export interface Player {
    name: string;
    isLeader: boolean;
    role: number;
    picture: string;
    description: string;
    discord: string;
  }
  
  export interface Team {
    name: string;
    logo: string;
    recruitStatus: number[];
    description: string;
    Palmares: Palmares[];
    players: Player[];
  }
  
  function GetRoleIcon(index:number){
    switch(index){
      case 0:
        return `${process.env.PUBLIC_URL}/roles/Position_Challenger-Top.png`;
      case 1:
        return `${process.env.PUBLIC_URL}/roles/Position_Challenger-Jungle.png`;
      case 2:
        return `${process.env.PUBLIC_URL}/roles/Position_Challenger-Mid.png`;
      case 3:
        return `${process.env.PUBLIC_URL}/roles/Position_Challenger-Bot.png`;
      case 4:
        return `${process.env.PUBLIC_URL}/roles/Position_Challenger-Support.png`;
    }
  }

  const Teams = () => {
    const [teams, setTeams] = useState<Team[]>([]);
  
    useEffect(() => {
      setTeams(teamsData.teams);
    }, []);
  
    return (
      <div className="w-full p-6 bg-raisinBlack">
        <div className='grid md:grid-cols-3 gap-4'>
          {teams.length > 0 ? (
                    teams.map((team, index) => (team.name == "" ? <></> : (
                        <button key={index} className='border border-syracuseRedOrange' onClick={() => window.open(`/${team.name.replaceAll(" ", "_")}`, '_blank', 'noopener,noreferrer')}>
                <h2 className='w-full text-center text-2xl bold py-2'>{team.name}</h2>
                <img src={`${process.env.PUBLIC_URL}${team.logo}`} className='px-4 py-2 scale-95 hover:scale-100' />
                {team.recruitStatus.find((value) => value > 0) !== undefined ? <p className='mt-4'>Recherche:</p> : <p className='w-full mt-4'>Ne recrute pas</p>}
                <div className='grid grid-cols-5 gap-0 justify-center mx-8 md:mx-4 mb-4'>  
                    {team.recruitStatus.map((status, index) => (
                        <img src={GetRoleIcon(index)} className={status === 1 ? 'opacity-100' : 'opacity-20'} />
                    ))}
                </div>
              </button>
            ))
          )) : (
            <p>Chargement des équipes...</p>
          )}
          <button className='border border-syracuseRedOrange' onClick={()=>alert(`Et non, il n'y a pas de vrai équipe par ici, bien essayé ! Pour créer une équipe il faut etre adhérent HPM et contacter un membre du staff via Discord !`)}>
            <h2 className='w-full text-center text-2xl bold py-2'>Votre équipe ici !</h2>
            <img src="./logo_HPM_orange.svg" className='px-4 py-2 scale-95 hover:scale-100' />
            <p className='mt-4'>Recherche:</p>
                    <div className='grid grid-cols-5 gap-0 justify-center mx-8 md:mx-4 mb-4'>  
                <img src={`${process.env.PUBLIC_URL}/roles/Position_Challenger-Top.png`} className=''/>
                <img src={`${process.env.PUBLIC_URL}/roles/Position_Challenger-Jungle.png`} className=''/>
                <img src={`${process.env.PUBLIC_URL}/roles/Position_Challenger-Mid.png`} className=''/>
                <img src={`${process.env.PUBLIC_URL}/roles/Position_Challenger-Bot.png`} className=''/>
                <img src={`${process.env.PUBLIC_URL}/roles/Position_Challenger-Support.png`} className=''/>   
            </div>
          </button>
        </div>
      </div>
    );
  };

export default Teams; 