import React from 'react';
import logo from './logo.svg';
import './App.css';
import MenuBar from './components/menuBar';
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Planning from './pages/planning';
import Team from './pages/team';
import Teams from './pages/teams';
import Contact from './pages/contact';
import Sponsor from './pages/sponsor';

function App() {
    return (
        <div>
            <MenuBar />
            <div className="pt-24 md:px-[15%] px-0 bg-raisinBlacker text-white w-full h-full min-h-screen">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/planning" element={<Planning />} />
                    <Route path="/teams" element={<Teams />} />
                    <Route path="/:teamName" element={<Team />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/partenaires" element={<Sponsor />} />
                </Routes>
            </BrowserRouter>
            </div>
        </div>
    );
}

export default App;
