import React, { useState } from 'react';
import { Bars3Icon } from '@heroicons/react/20/solid';

const MenuBar = () => {

    const [isPanelOpen, setIsPanelOpen] = useState(false);

    const togglePanel = () => {
        setIsPanelOpen(!isPanelOpen);
    };

    return (
        <header className="bg-raisinBlack text-white fixed w-full top-0 left-0 shadow-md z-50">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex items-center h-16">
                    {/* Logo ou Titre */}
                    <a href="/"> 
                        <div className="flex-shrink-0 col-2 flex items-center">
                            <img src={`${process.env.PUBLIC_URL}/logo_HPM_orange.svg`} className="h-10 mr-3" alt="logo"/>
                            <h1 className="text-lg font-bold">Hard Push Mid</h1>
                        </div>
                    </a>

                    <div className="bg-white h-[80%] mx-3 text-sm">.</div>

                    {/* Menu Items */}
                    <nav className="hidden md:flex space-x-4">
                        <a href="/" className="hover:underline decoration-4 decoration-syracuseRedOrange underline-offset-8 px-3 py-2 rounded-md text-sm font-medium">Accueil</a>
                        <a href="/planning" className="hover:underline decoration-4 decoration-syracuseRedOrange underline-offset-8 px-3 py-2 rounded-md text-sm font-medium">Planning</a>
                        <a href="/teams" className="hover:underline decoration-4 decoration-syracuseRedOrange underline-offset-8 px-3 py-2 rounded-md text-sm font-medium">Equipes</a>
                        <a href="https://www.youtube.com/@HardPushMid" target="_blank" rel="noopener noreferrer" className="hover:underline decoration-4 decoration-syracuseRedOrange underline-offset-8 px-3 py-2 rounded-md text-sm font-medium">VODs</a>
                        <a href="/contact" className="hover:underline decoration-4 decoration-syracuseRedOrange underline-offset-8 px-3 py-2 rounded-md text-sm font-medium">Nous Rejoindre</a>
                        <a href="/partenaires" className="hover:underline decoration-4 decoration-syracuseRedOrange underline-offset-8 px-3 py-2 rounded-md text-sm font-medium">Partenaires</a>
                    </nav>

                    {/* Menu Burger pour les petits ecrans */}
                    <div className="md:hidden">
                        <button className="" onClick={togglePanel}>
                            <Bars3Icon className="h-7 w-7 text-white" />
                        </button>
                    </div>
                </div>
            </div>
            {isPanelOpen && (
                <div className="h-screen w-screen bg-raisinBlack text-xl m-0 p-0">
                    <nav className="grid grid-cols-1">
                        <a href="/" className="hover:underline decoration-4 decoration-syracuseRedOrange underline-offset-8 px-6 py-6 rounded-md font-medium">Accueil</a>
                        <a href="/planning" className="hover:underline decoration-4 decoration-syracuseRedOrange underline-offset-8 px-6 py-6 rounded-md font-medium">Planning</a>
                        <a href="/teams" className="hover:underline decoration-4 decoration-syracuseRedOrange underline-offset-8 px-6 py-6 rounded-md font-medium">Equipes</a>
                        <a href="https://www.youtube.com/@HardPushMid" target="_blank" rel="noopener noreferrer" className="hover:underline decoration-4 decoration-syracuseRedOrange underline-offset-8 px-6 py-6 rounded-md font-medium">VODs</a>
                        <a href="/contact" className="hover:underline decoration-4 decoration-syracuseRedOrange underline-offset-8 px-6 py-6 rounded-md font-medium">Nous Rejoindre</a>
                        <a href="/partenaires" className="hover:underline decoration-4 decoration-syracuseRedOrange underline-offset-8 px-6 py-6 rounded-md font-medium">Partenaires</a>
                        <span onClick={togglePanel} className="hover:underline decoration-4 decoration-syracuseRedOrange underline-offset-8 px-6 py-6 rounded-md font-medium w-full">Fermer</span>
                    </nav>
                </div>
            )}
        </header>
    );
};

export default MenuBar;