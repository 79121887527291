import React, { useState, useEffect } from 'react';
import teamsData from '../data/teams.json';
import { useParams } from 'react-router-dom';

export interface Palmares {
    place: number;
    tournament: string;
}

export interface Player {
    name: string;
    isLeader: boolean;
    role: number;
    picture: string;
    description: string;
    discord: string;
}

export interface Team {
    name: string;
    logo: string;
    recruitStatus: number[];
    description: string;
    Palmares: Palmares[];
    players: Player[];
}



const Team = () => {
    const { teamName } = useParams(); // Récupère le nom de l'équipe depuis l'URL
    const [team, setTeam] = useState<Team | null>(null);

    function GetRoleIcon(index: number) {
        switch (index) {
            case 1:
                return `${process.env.PUBLIC_URL}/roles/Position_Challenger-Top.png`;
            case 2:
                return `${process.env.PUBLIC_URL}/roles/Position_Challenger-Jungle.png`;
            case 3:
                return `${process.env.PUBLIC_URL}/roles/Position_Challenger-Mid.png`;
            case 4:
                return `${process.env.PUBLIC_URL}/roles/Position_Challenger-Bot.png`;
            case 5:
                return `${process.env.PUBLIC_URL}/roles/Position_Challenger-Support.png`;
        }
    }

    function GetPlayers() {
        const rows = [];
        for (let i = 1; i < 6; i++) {
            let player = team?.players.find((player) => player.role == i);
            rows.push(
                <div key={i} className="relative flex flex-col items-center p-4 border border-white">
                    {player?.isLeader && (
                        <img
                            src={`${process.env.PUBLIC_URL}/roles/leader.png`}
                            className="absolute top-20 right-0 w-[25%] m-2"
                            alt="Leader Icon"
                        />
                    )}
                    <p className="text-center py-4 text-2xl bold flex items-center justify-center">
                        <img src={GetRoleIcon(i)} alt="Role Icon" className="float-left h-9" />
                        {player == undefined ? "Peut être vous ?" : player.name}
                    </p>
                    <img
                        src={player == undefined ? GetRoleIcon(i) : player.picture}
                        alt={player == undefined ? "Image du joueur" : player.name}
                        className={player == undefined ? 'my-28' : 'mb-2'}
                    />
                    <p className="text-center">
                        {player == undefined ? "Nous recherchons actuellement un joueur sur ce rôle." : player.description}
                    </p>
                    {player?.discord && (
                        <p className="text-center">Discord: {player.discord}</p>
                    )}
                </div>
            );
        }
        return rows;
    }

    function GetPalmares() {
        let palmares = team?.Palmares.length;
        const elements = [];

        const colors = ['text-yellow-500', 'text-grey-500', 'text-yellow-800']

        if (palmares == undefined || palmares == 0)
            return <br />

        for (let i = 0; i < palmares; i++) {
            let current = team?.Palmares[i];
            if (current?.place != undefined) {
                elements.push(
                    <div className='text-2xl'><span className={`${current?.place > 3 ? "text-white" : colors[current?.place - 1]}`}>{current?.place}</span> - {current?.tournament}</div>
                );
            }
        }
        return elements;
    }

    useEffect(() => {
        if (teamName) {
            // Cherche l'équipe correspondante dans les données JSON
            const foundTeam = teamsData.teams.find(t => t.name.replaceAll(" ", "_") === teamName);
            setTeam(foundTeam || null); // Assure-toi que setTeam reçoit une valeur correcte
        }
    }, [teamName]);

    if (team === null) {
        return <p>Équipe non trouvée : {teamName}</p>; // Affiche un message si l'équipe n'est pas trouvée
    }

    return (
        <div>
            <div className='grid grid-cols-2 text-center border border-syracuseRedOrange py-4 px-16 gap-8'>
                <div className='h-full'>
                    <h1 className='text-2xl text-bold mb-2 w-full'>{team.name}</h1>
                    <img src={`${process.env.PUBLIC_URL}${team.logo}`} className='w-full p-8' />
                </div>
                <div className='h-full'>
                    <p dangerouslySetInnerHTML={{ __html: team.description }}></p>
                    <p className='mt-6'>Recrutement :</p>
                    <div className='grid grid-cols-7 gap-0 justify-center mx-8 md:mx-4 mb-4'>
                        <div></div>
                        <img src={`${process.env.PUBLIC_URL}/roles/Position_Challenger-Top.png`} className={team.recruitStatus[0] === 1 ? 'opacity-100' : 'opacity-20'} />
                        <img src={`${process.env.PUBLIC_URL}/roles/Position_Challenger-Jungle.png`} className={team.recruitStatus[1] === 1 ? 'opacity-100' : 'opacity-20'} />
                        <img src={`${process.env.PUBLIC_URL}/roles/Position_Challenger-Mid.png`} className={team.recruitStatus[2] === 1 ? 'opacity-100' : 'opacity-20'} />
                        <img src={`${process.env.PUBLIC_URL}/roles/Position_Challenger-Bot.png`} className={team.recruitStatus[3] === 1 ? 'opacity-100' : 'opacity-20'} />
                        <img src={`${process.env.PUBLIC_URL}/roles/Position_Challenger-Support.png`} className={team.recruitStatus[4] === 1 ? 'opacity-100' : 'opacity-20'} />
                        <div></div>
                    </div>
                </div>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-5 w-full h-full m-0'>
                {GetPlayers()}
            </div>

            <h1 className='w-full text-center text-2xl my-4 bold'>Palmares de l'équipe</h1>
            <div className='w-full flex items-center justify-center gap-7'>
                {GetPalmares()}
            </div>
        </div>
    );
};

export default Team;